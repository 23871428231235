import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useState, useEffect } from "react";
import api from "../api.js";
import { Polyline } from "@react-google-maps/api";
// import { pathsArray } from "../gpxdata/setOfJsons"

// const containerStyle = {
//     width: '100%',
//     height: '640px'
// };

const center = {
  lat: 46.5193587,
  lng: 12.0051628,
};

function MapModule(props) {
  const { cardId, polygon, cardItemsList, mapId } = props;

  const [map, setMap] = React.useState(null);
  //   const [cardItemsList, setCardImetsList] = useState([]);

  const polylineOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    // paths: pathsArray[trackNumber],
    paths: polygon.polyline,
    zIndex: 1,
  };

  //   const helloFromServer = async () => {
  //     let res = await api
  //       .get("/api/cards?populate=*")
  //       .then((res) => setCardImetsList(res.data.data))
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  //   useEffect(() => {
  //     helloFromServer();
  //   }, []);

  useEffect(() => {
    // console.log("useEffect: cardId= ", cardId);
    // console.log("useEffect: mapId= ", mapId);

    if (!map) return;
    zoomToObject(polygon.polyline);
    console.log("zoom");
  }, [mapId]);

  useEffect(() => {
    // console.log("useEffect: cardId= ", cardId);
    // console.log("useEffect: mapId= ", mapId);

    if (!map) return;
    zoomToObject(polygon.polyline);
    console.log("zoom");
  }, []);

  const API_KEY = process.env.REACT_APP_googleMapsApiKey;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
  });

  // console.log(API_KEY);

  function zoomToObject() {
    var bounds = new window.google.maps.LatLngBounds();
    var points = polygon.polyline;
    for (var n = 0; n < points.length; n++) {
      bounds.extend(points[n]);
    }
    map.fitBounds(bounds);
    // console.log("zoomToObject: points", points)
  }

  const onLoad = React.useCallback(function callback(map) {
    var bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div
      style={{
        display: "flex",
        flexGrow: "1",
        backgroundColor: "blue",
        flexDirection: "row",
      }}
    >
      <GoogleMap
        // mapContainerStyle={containerStyle}
        mapContainerClassName="map"
        center={center}
        zoom={5}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Polyline
          // onLoad={onPolylineLoad}
          //   onLoad={zoomToObject}
          // path={pathsArray[trackNumber]}
          path={polygon.polyline}
          options={polylineOptions}
          zoom={5}
        />
        <></>
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

export default React.memo(MapModule);
