import axios from "axios";

const baseURL = process.env.REACT_APP_API;

const api = axios.create({
  baseURL,
  // baseURL: "https://strapi-for-dolomites.onrender.com",
  timeout: 10000,
});

export default api;
