import { useState, useEffect, useRef } from "react";
import api from "../api.js";
import Typography from "@mui/material/Typography";
import { height } from "@mui/system";

const API_URL = process.env.REACT_APP_API;

export default function Home() {
  const [homeGallery, setHomeGallery] = useState(null);
  const [imgLoaded, setImgLoaded] = useState(0);
  const imgLoadedRef = useRef(0);

  useEffect(() => {
    helloFromServer();
  }, []);

  const helloFromServer = async () => {
    let res = await api
      .get("api/home?populate=*")
      .then((res) => {
        const homeGallery = res.data.data.attributes.homeGallery.data;
        setHomeGallery(homeGallery);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onImageLoad = () => {
    setImgLoaded(imgLoadedRef.current++ + 1);
    // console.log(imgLoaded);
  };

  if (!homeGallery) return "loading...";

  const names = ["first", "second", "third", "fourth"];
  // const names = ["fourth", "third", "second", "first"];

  console.log(imgLoaded);

  return (
    <div className="bigBox">
      {/* <div
        className="leftSideBox"

      >
        EXPLORE MTB TRACKS
      </div> */}

      <div className="box-a">
        <div className="box-aa">
          <div className="box-aaa">
            <span className="big-title">
              mtb
              {/* {imgLoaded} */}
              <br /> best
              <br /> rides
              <br /> blog
            </span>
            <span className="under-title">
              You can find here short despriptions of wonderful trips (mtb
              mostly) with photogalleries, dynamic maps and gpx files for
              navigation devices.
            </span>
            <a href="/explore" className="explore-button">
              <span className="top-bt-bg"></span>Explore
            </a>
          </div>
        </div>
      </div>

      <div className="rightSideBox">
        {/* {imgLoaded < 4 && (
          <img
            className="home-image-reduced"
            src={`${API_URL}${homeGallery[3].attributes.formats.thumbnail.url}`}
          />
        )} */}

        <div className="imagesContainer">
          {homeGallery.map((imgUrl, idx) => (
            <div className={`${names[idx]}-img`} key={`${idx}-cont`}>
              <img
                // className="home-image"
                className={`home-image ${
                  imgLoaded < 4 ? "home-image-reduced" : ""
                }`}
                key={`${idx}-img`}
                src={`${API_URL}${imgUrl.attributes.formats.xlarge.url}`}
                onLoad={onImageLoad}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
