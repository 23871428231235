import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  gpxFileUrl,
  openGpxDialog,
  setOpenGpxDialog,
  cardTitle,
}) {
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // console.log("open =", open);

  const handleClose = () => {
    setOpenGpxDialog(false);
    // setDisplayOption("gallery");
  };

  return (
    <div>
      {/* <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{ color: "white" }}
        class="css-sghohy-MuiButtonBase-root-MuiButton-root"
      >
        GPX
      </Button> */}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openGpxDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Gpx download
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Download track file of the "{cardTitle}" in gpx format
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button href={gpxFileUrl} autoFocus onClick={handleClose}>
            Download
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
